/* eslint-disable import/max-dependencies */
import React from "react";
import { Link } from "gatsby";
import PageLayout from "../components/layouts/page-layout";
import SiteHeader from "../components/SiteHeader";
import SiteFooter from "../components/SiteFooter";
import Container from "../../packages/Container/src";
import { Heading, P } from "../../packages/Typography/src";
import { Grid, GridItem } from "../../packages/Grid/src";
import Div from "../../packages/Div/src";
import RatioContainer from "../../packages/RatioContainer/src";
import Button from "../../packages/Button/src";
import Image from "../../packages/Image/src";
import img1 from "../images/home-page/image-1.png";

var IndexPage = function IndexPage() {
  return React.createElement(PageLayout, null, React.createElement(SiteHeader, null), React.createElement(Container, {
    size: "md",
    mt: "2.5rem"
  }, React.createElement(Grid, {
    alignItems: "center"
  }, React.createElement(GridItem, {
    gridColumn: ["span 12", "span 6"]
  }, React.createElement(Heading, {
    as: "h1",
    mt: "0"
  }, "DS-kit"), React.createElement(P, {
    size: "lg"
  }, "The design system behind ", React.createElement("a", {
    href: "https://datastory.org/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Datastory.", " "), "Ds-kit aims to help the Datastory team develop consistent and effective apps, stories, topics and other products."), React.createElement(Button, {
    as: Link,
    to: "/getting-started",
    size: "lg",
    variant: "success",
    borderRadius: "pill"
  }, "Get started")), React.createElement(GridItem, {
    gridColumn: ["span 12", "span 6"]
  }, React.createElement(Image, {
    src: img1,
    alt: "DS-Kit cover"
  })))), React.createElement(Container, {
    size: "md",
    py: "2.5rem"
  }, React.createElement(Grid, null, React.createElement(GridItem, {
    gridColumn: ["span 12", "span 6", null, "span 3"]
  }, React.createElement(Div, {
    width: "5rem"
  }, React.createElement(Link, {
    to: "/typography"
  }, React.createElement(RatioContainer, {
    ratio: 1,
    bg: "transparent"
  }, React.createElement("svg", {
    width: "80px",
    height: "80px",
    viewBox: "0 0 80 80"
  }, React.createElement("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd"
  }, React.createElement("g", {
    transform: "translate(16, 16)",
    fillRule: "nonzero"
  }, React.createElement("path", {
    d: "M6.26086957,34.4347826 L6.26086957,14.6086957 L4.17391304,14.6086957 L4.17391304,34.4347826 C4.17966379,39.6190729 8.38092712,43.8203362 13.5652174,43.826087 L33.3913043,43.826087 L33.3913043,41.7391304 L13.5652174,41.7391304 C9.53304453,41.7345301 6.26546988,38.4669555 6.26086957,34.4347826 Z",
    fill: "#FFC830"
  }), React.createElement("path", {
    d: "M41.7391304,13.5652174 L41.7391304,33.3913043 L43.826087,33.3913043 L43.826087,13.5652174 C43.8203362,8.38092712 39.6190729,4.17966379 34.4347826,4.17391304 L14.6086957,4.17391304 L14.6086957,6.26086957 L34.4347826,6.26086957 C38.4669555,6.26546988 41.7345301,9.53304453 41.7391304,13.5652174 Z",
    fill: "#FFC830"
  }), React.createElement("rect", {
    fill: "#AF8000",
    x: "0",
    y: "0",
    width: "10.4347826",
    height: "10.4347826",
    rx: "1"
  }), React.createElement("rect", {
    fill: "#AF8000",
    x: "37.5652174",
    y: "37.5652174",
    width: "10.4347826",
    height: "10.4347826",
    rx: "1"
  }))))))), React.createElement(Heading, {
    as: "h3",
    size: "xl",
    mt: "0"
  }, React.createElement(Link, {
    to: "/typography"
  }, "Design")), React.createElement(P, {
    my: "0"
  }, "Design guidelines on typography, colors, icons and illustrations.")), React.createElement(GridItem, {
    gridColumn: ["span 12", "span 6", null, "span 3"]
  }, React.createElement(Div, {
    width: "5rem"
  }, React.createElement(Link, {
    to: "/packages/avatar"
  }, React.createElement(RatioContainer, {
    ratio: 1,
    bg: "transparent"
  }, React.createElement("svg", {
    width: "81px",
    height: "80px",
    viewBox: "0 0 81 80"
  }, React.createElement("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    transform: "translate(1, 0)"
  }, React.createElement("g", {
    transform: "translate(16, 16)",
    fillRule: "nonzero"
  }, React.createElement("rect", {
    fill: "#005680",
    x: "0",
    y: "0",
    width: "13.5652174",
    height: "48",
    rx: "1"
  }), React.createElement("rect", {
    fill: "#55C8FF",
    x: "36.5217391",
    y: "16.6956522",
    width: "11.4782609",
    height: "31.3043478",
    rx: "1"
  }), React.createElement("rect", {
    fill: "#00ACFF",
    x: "17.7391304",
    y: "0",
    width: "30.2608696",
    height: "12.5217391",
    rx: "1"
  }), React.createElement("rect", {
    fill: "#55C8FF",
    x: "17.7391304",
    y: "34.4347826",
    width: "14.6086957",
    height: "13.5652174",
    rx: "1"
  }), React.createElement("rect", {
    fill: "#55C8FF",
    x: "17.7391304",
    y: "16.6956522",
    width: "14.6086957",
    height: "13.5652174",
    rx: "1"
  }))))))), React.createElement(Heading, {
    as: "h3",
    size: "xl",
    mt: "0"
  }, React.createElement(Link, {
    to: "/packages/avatar"
  }, "Components")), React.createElement(P, {
    my: "0"
  }, "React components to build new apps, pages and other products more effectively.")), React.createElement(GridItem, {
    gridColumn: ["span 12", "span 6", null, "span 3"]
  }, React.createElement(Div, {
    width: "5rem"
  }, React.createElement(Link, {
    to: "/patterns"
  }, React.createElement(RatioContainer, {
    ratio: 1,
    bg: "transparent"
  }, React.createElement("svg", {
    width: "80px",
    height: "80px",
    viewBox: "0 0 80 80"
  }, React.createElement("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd"
  }, React.createElement("g", {
    transform: "translate(11, 16)",
    fillRule: "nonzero"
  }, React.createElement("path", {
    d: "M35.3684211,3.78947368 L0,3.78947368 L0,45.4736842 C2.80477396e-16,46.8689299 1.13107011,48 2.52631579,48 L35.3684211,48 L35.3684211,3.78947368 Z",
    fill: "#EBEBEB"
  }), React.createElement("path", {
    d: "M58.1052632,3.78947368 L34.1052632,3.78947368 L34.1052632,48 L55.5789474,48 C56.9741931,48 58.1052632,46.8689299 58.1052632,45.4736842 L58.1052632,3.78947368 Z",
    fill: "#511EE9"
  }), React.createElement("path", {
    d: "M50.5263158,25.2631579 L41.6842105,25.2631579 C40.9865877,25.2631579 40.4210526,24.6976228 40.4210526,24 C40.4210526,23.3023772 40.9865877,22.7368421 41.6842105,22.7368421 L50.5263158,22.7368421 C51.2239386,22.7368421 51.7894737,23.3023772 51.7894737,24 C51.7894737,24.6976228 51.2239386,25.2631579 50.5263158,25.2631579 Z",
    fill: "#FFFFFF"
  }), React.createElement("path", {
    d: "M50.5263158,17.6842105 L41.6842105,17.6842105 C40.9865877,17.6842105 40.4210526,17.1186755 40.4210526,16.4210526 C40.4210526,15.7234298 40.9865877,15.1578947 41.6842105,15.1578947 L50.5263158,15.1578947 C51.2239386,15.1578947 51.7894737,15.7234298 51.7894737,16.4210526 C51.7894737,17.1186755 51.2239386,17.6842105 50.5263158,17.6842105 Z",
    fill: "#FFFFFF"
  }), React.createElement("path", {
    d: "M50.5263158,32.8421053 L41.6842105,32.8421053 C40.9865877,32.8421053 40.4210526,32.2765702 40.4210526,31.5789474 C40.4210526,30.8813245 40.9865877,30.3157895 41.6842105,30.3157895 L50.5263158,30.3157895 C51.2239386,30.3157895 51.7894737,30.8813245 51.7894737,31.5789474 C51.7894737,32.2765702 51.2239386,32.8421053 50.5263158,32.8421053 Z",
    fill: "#FFFFFF"
  }), React.createElement("path", {
    d: "M50.5263158,40.4210526 L41.6842105,40.4210526 C40.9865877,40.4210526 40.4210526,39.8555176 40.4210526,39.1578947 C40.4210526,38.4602719 40.9865877,37.8947368 41.6842105,37.8947368 L50.5263158,37.8947368 C51.2239386,37.8947368 51.7894737,38.4602719 51.7894737,39.1578947 C51.7894737,39.8555176 51.2239386,40.4210526 50.5263158,40.4210526 Z",
    fill: "#FFFFFF"
  }), React.createElement("path", {
    d: "M2.52631579,0 L55.5789474,0 C56.9741931,0 58.1052632,1.13107011 58.1052632,2.52631579 L58.1052632,7.57894737 L0,7.57894737 L0,2.52631579 C-1.40238698e-16,1.13107011 1.13107011,0 2.52631579,0 Z",
    fill: "#D6D6D7"
  }))))))), React.createElement(Heading, {
    as: "h3",
    size: "xl",
    mt: "0"
  }, React.createElement(Link, {
    to: "/patterns"
  }, "Patterns")), React.createElement(P, {
    my: "0"
  }, "Patterns using Datastory components to inspire and help build apps and pages faster.")), React.createElement(GridItem, {
    gridColumn: ["span 12", "span 6", null, "span 3"]
  }, React.createElement(Div, {
    width: "5rem"
  }, React.createElement(RatioContainer, {
    ratio: 1,
    bg: "transparent"
  }, React.createElement("svg", {
    width: "80px",
    height: "80px",
    viewBox: "0 0 80 80"
  }, React.createElement("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd"
  }, React.createElement("g", {
    transform: "translate(16, 16)",
    fillRule: "nonzero"
  }, React.createElement("path", {
    d: "M39.4701818,47.7010909 L13.8338182,42.3556364 C13.5498688,42.2966592 13.3010894,42.1270463 13.142448,41.8842738 C12.9838067,41.6415014 12.9283617,41.3455528 12.9883636,41.0618182 L19.752,9.04145455 C19.8791053,8.45518757 20.4534021,8.07961108 21.0414545,8.19818182 L46.5905455,13.5272727 C46.874002,13.5860403 47.1224779,13.7550618 47.2812562,13.9971169 C47.4400346,14.239172 47.4960945,14.5344107 47.4370909,14.8178182 L40.7607273,46.8578182 C40.6604321,47.3408267 40.2484779,47.6964224 39.756,47.7250909 C39.6602329,47.7271603 39.5641761,47.7201943 39.4701818,47.7010909 Z",
    fill: "#2CA76D"
  }), React.createElement("path", {
    d: "M27.2727273,34.9090909 L1.09090909,34.9090909 C0.488416637,34.9090909 0,34.4206743 0,33.8181818 L0,1.09090909 C0,0.488416637 0.488416637,0 1.09090909,0 L27.2727273,0 C27.8752197,0 28.3636364,0.488416637 28.3636364,1.09090909 L28.3636364,33.8181818 C28.3636364,34.4206743 27.8752197,34.9090909 27.2727273,34.9090909 Z",
    fill: "#78DBAC"
  })))))), React.createElement(Heading, {
    as: "h3",
    size: "xl",
    mt: "0"
  }, "Resources"), React.createElement(P, {
    my: "0"
  }, "A collection of sketch files, typefaces and useful urls to help use ds-kit.")))), React.createElement(SiteFooter, null));
};

export default IndexPage;